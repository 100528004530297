@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /**
 * Chrome has a bug with transitions on load since 2012!
 *
 * To prevent a "pop" of content, you have to disable all transitions until
 * the page is done loading.
 *
 * https://lab.laukstein.com/bug/input
 * https://twitter.com/timer150/status/1345217126680899584
 */
  body.loading * {
    transition: none !important;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(var(--oxford-gray-300) / var(--tw-bg-opacity));
    border-radius: 16px;
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #8e8e91;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }

  :root {
    --color-primary: 80 5 242;

    --electric-violet-900: 59 7 171;
    --electric-violet-800: 70 5 210;
    --electric-violet-700: 80 5 242;
    --electric-violet-600: 98 25 255;
    --electric-violet-500: 114 63 255;
    --electric-violet-400: 147 118 255;
    --electric-violet-300: 182 168 255;
    --electric-violet-200: 212 207 255;
    --electric-violet-100: 233 229 255;
    --electric-violet-50: 243 241 255;

    --cornflower-blue-900: 43 53 125;
    --cornflower-blue-800: 47 58 158;
    --cornflower-blue-700: 51 69 194;
    --cornflower-blue-600: 60 88 212;
    --cornflower-blue-500: 81 116 224;
    --cornflower-blue-400: 107 146 231;
    --cornflower-blue-300: 159 187 241;
    --cornflower-blue-200: 198 214 247;
    --cornflower-blue-100: 223 231 250;
    --cornflower-blue-50: 241 244 253;

    --electric-pink-900: 133 10 128;
    --electric-pink-800: 164 4 161;
    --electric-pink-700: 201 2 200;
    --electric-pink-600: 238 7 242;
    --electric-pink-500: 255 40 251;
    --electric-pink-400: 255 98 246;
    --electric-pink-300: 255 156 251;
    --electric-pink-200: 255 200 255;
    --electric-pink-100: 255 228 255;
    --electric-pink-50: 255 242 255;

    --jade-900: 0 85 57;
    --jade-800: 0 103 68;
    --jade-700: 0 131 85;
    --jade-600: 0 164 101;
    --jade-500: 0 181 111;
    --jade-400: 37 226 147;
    --jade-300: 99 242 177;
    --jade-200: 160 250 204;
    --jade-100: 205 254 226;
    --jade-50: 234 255 244;

    --watermelon-900: 132 24 32;
    --watermelon-800: 160 20 30;
    --watermelon-700: 193 20 33;
    --watermelon-600: 229 29 44;
    --watermelon-500: 248 59 73;
    --watermelon-400: 255 108 119;
    --watermelon-300: 255 160 167;
    --watermelon-200: 255 199 203;
    --watermelon-100: 255 225 227;
    --watermelon-50: 255 241 242;

    --candlelight-900: 113 67 18;
    --candlelight-800: 133 82 14;
    --candlelight-700: 160 104 8;
    --candlelight-600: 201 146 5;
    --candlelight-500: 233 188 9;
    --candlelight-400: 249 212 22;
    --candlelight-300: 252 230 72;
    --candlelight-200: 254 245 138;
    --candlelight-100: 254 251 195;
    --candlelight-50: 254 253 234;

    --oxford-gray-900: 51 59 71;
    --oxford-gray-800: 55 65 81;
    --oxford-gray-700: 65 78 99;
    --oxford-gray-600: 80 96 121;
    --oxford-gray-500: 100 119 147;
    --oxford-gray-400: 132 149 172;
    --oxford-gray-300: 175 186 202;
    --oxford-gray-200: 212 218 227;
    --oxford-gray-100: 236 238 242;
    --oxford-gray-50: 246 247 249;

    --white-gray: 249 250 251;
    --white: 255 255 255;
    --black: 0 0 0;
  }

  html {
    height: 100%;
    box-sizing: border-box;
    touch-action: manipulation;
    font-feature-settings:
      'case' 1,
      'rlig' 1,
      'calt' 0;
    text-rendering: optimizeLegibility;
  }

  html,
  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    position: relative;
    min-height: 100%;
    margin: 0;
  }

  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  a:hover {
    text-decoration: none;
  }

  h6 {
    color: #667b95;
    font-size: 1rem;
  }

  [type='text']:focus,
  [type='email']:focus,
  [type='url']:focus,
  [type='password']:focus,
  [type='number']:focus,
  [type='date']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='week']:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    outline: none;
    box-shadow: none;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.875rem;
    line-height: 1.9rem;
  }

  input::placeholder,
  textarea::placeholder {
    color: rgb(var(--oxford-gray-300));
  }

  :focus-visible {
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .show-scrollbar::-webkit-scrollbar {
    display: block;
  }

  .show-scrollbar {
    -ms-overflow-style: auto; /* IE and Edge */
    scrollbar-width: auto; /* Firefox */
  }

  .loading-ellipsis::after {
    display: inline-block;
    animation: dotty steps(1, end) 2s infinite;
    content: '';
  }
}

@keyframes highlight {
  75%,
  100% {
    box-shadow: 0 0 0px 12px rgb(var(--electric-violet-800));
    opacity: 0;
  }
}

@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
